import FlashLib from 'flashlib_onlyplay';
import { jackpotGrand, jackpotMajor, jackpotMega, jackpotMini, jackpotMinor } from '../../../fontStyles';
import GlobalDispatcher from 'Engine/events/GlobalDispatcher';
import EntryPoint from 'Engine/EntryPoint';
import MoneyFormat from 'Engine/base/moneyFormat/MoneyFormat';
import LocalizeTextField from '../../../libs/game-engine/src/base/localization/LocalizeTextField';

export default class ControllerJackpotPanels extends FlashLib.MovieClip{
  constructor(data, displayData) {
    super(data, displayData);
    this.init()
    this.setTextStile();
    this.updatePanels();
    this.addListeners()
  }

  init() {
    this.grandPanel = this.getChildByName('grand');
    this.grandPanelAmount = this.grandPanel.getChildByName('textAmount');
    this.grandPanelTitle = this.grandPanel.getChildByName('Jackpot_panel_title_grand');
    this.megaPanel = this.getChildByName('mega');
    this.megaPanelAmount = this.megaPanel.getChildByName('textAmount');
    this.megaPanelTitle = this.megaPanel.getChildByName('Jackpot_panel_title_mega');
    this.majorPanel = this.getChildByName('major');
    this.majorPanelAmount = this.majorPanel.getChildByName('textAmount');
    this.majorPanelTitle = this.majorPanel.getChildByName('Jackpot_panel_title_major');
    this.minorPanel = this.getChildByName('minor');
    this.minorPanelAmount = this.minorPanel.getChildByName('textAmount');
    this.minorPanelTitle = this.minorPanel.getChildByName('Jackpot_panel_title_minor');
    this.miniPanel = this.getChildByName('mini');
    this.miniPanelAmount = this.miniPanel.getChildByName('textAmount');
    this.miniPanelTitle = this.miniPanel.getChildByName('Jackpot_panel_title_mini');
  }

  addListeners() {
    GlobalDispatcher.add('model:getServerData', this.updatePanels, this)
    GlobalDispatcher.add('model:betChanged', this.updatePanels, this);
    if (this.libData.timeline.frameCount > 1) window.OPWrapperService.eventManager.add(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );

  }

  removeListeners() {
    GlobalDispatcher.remove('model:getServerData', this.updatePanels, this)
    GlobalDispatcher.remove('model:betChanged', this.updatePanels, this);
    if (this.libData.timeline.frameCount > 1) window.OPWrapperService.eventManager.remove(
          window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE,
          this.onResize,
          this
        );

  }

  onResize (data) {
    if (data.isLandscape) {
      this.goToFrame(2)
      this.init();
      this.setTextStile();
      this.updatePanels();
    } else if (data.isPortrait) {
      this.goToFrame(1);
      this.init();
      this.setTextStile();
      this.updatePanels();
    }
  }
  setTextStile() {
    if (this.grandPanelTitle) this.grandPanelTitle.style = { ...this.grandPanelTitle.style, ...jackpotGrand }
    if (this.megaPanelTitle) this.megaPanelTitle.style = { ...this.megaPanelTitle.style, ...jackpotMega }
    if (this.majorPanelTitle) this.majorPanelTitle.style = { ...this.majorPanelTitle.style, ...jackpotMajor }
    if (this.minorPanelTitle) this.minorPanelTitle.style = { ...this.minorPanelTitle.style, ...jackpotMinor }
    if (this.miniPanelTitle) this.miniPanelTitle.style = { ...this.miniPanelTitle.style, ...jackpotMini }
  }

  updatePanels() {
    const showCurrency = EntryPoint.GameModel.currencyInfo.decimals <= 3;
    [
      this.miniPanelAmount,
      this.minorPanelAmount,
      this.majorPanelAmount,
      this.megaPanelAmount,
      this.grandPanelAmount
    ].forEach((panel, index) => {
      panel.text = MoneyFormat.format(EntryPoint.GameModel.jackpotList[index], showCurrency);
      panel.correctPosition = function () {
        LocalizeTextField.prototype.correctPosition.call(this, 'center', 'center')
      };
      panel.fitSize();
    })
  }

  destroy(){
    this.removeListeners();
    super.destroy({children: true})
  }
}
